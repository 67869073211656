import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import callApi from '../../utils/apiCaller';
let token;
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
    
    }
  }

  async componentDidMount() {
    token = localStorage.getItem('_auth');
    if (token) {
      
    } else {
      this.setState({
        redirect: true
      })    
    }
  }

  renderAdmin(){
    const { user } = this.props;
    return <nav className="side-navbar">
    <div className="sidebar-header d-flex align-items-center">
      <div className="title">
            <h3 >{user && user.name ? user.name : null}</h3>
            <h1 className="h4">{user.role.description }</h1>
     </div>
    </div>      
    <span className="heading">QUẢN LÝ</span>
    
      <ul className="list-unstyled">
        {/* <li><Link to="/"> <i className="icon-home" />Trang chủ </Link></li> */}
        <li><Link to="/cong-viec"> <i className="icon icon-bill" />Công việc</Link></li>
        <li><Link to="/users"> <i className="icon icon-user" />Người dùng</Link></li>  
        <li><Link to="/ky-nang"> <i className="icon-interface-windows" />Kỹ năng</Link></li>
        <li><Link to="/hoa-don"> <i className="icon icon-grid" />Hóa đơn</Link></li>    
       
       </ul>
      
      
    
     
  </nav>
  }
  render() {
    const { user } = this.props;
    if (user.role=== undefined || user.role=== null){
      return(<div></div>)
    }else
    return (
     
      this.renderAdmin()
    )
  }
}

const mapStateToProps = (state) => {

  return {
    nameRole: state.nameRole,
    user:state.infoMe
  }
}

export default connect(mapStateToProps, null)(NavBar)
import React, { Component } from 'react'
import './style.css'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
class Report extends Component {
  render() {
    return (
        <div>
            hello
        </div>
    )
}
}


export default connect()(Report)

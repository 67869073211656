import * as Types from '../../constants/ActionType';
import callApi from '../../utils/apiCaller';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { actShowLoading, actHiddenLoading } from './loading'

export const actFetchUsersRequest = (token, offset) => {
  const newOffset = offset === null || offset === undefined ? 0 : offset;
  const limit = 10;
  return dispatch => {
    dispatch(actShowLoading());
    return new Promise((resolve, reject) => {
      callApi(`users?limit=${limit}&offset=${newOffset}&orderBy=-createdAt`, 'GET', null, token)
        .then(res => {
          if (res && res.status === 200) {
            dispatch(actFetchUsers(res.data.results));
            resolve(res.data);
            setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
          }
        })
        .catch(err => {
          console.log(err);
          reject(err);
          setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
        });
    });
  };
};

export const actFetchUsers = (users) => {
  return {
    type: Types.FETCH_USERS,
    users
  }
}

export const actFindUsersRequest = (token, searchText) => {
  return dispatch => {
  dispatch(actShowLoading());
  return new Promise((resolve, reject) => {
    if (searchText !== undefined && searchText !== null && searchText !== '') {
      callApi(`users?q=${searchText}`, 'GET', null, token)
      .then(res => {
        if (res && res.status === 200) {
          dispatch(actFindUsers(res.data.results));
          resolve(res.data);
          setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
        }
      })
      .catch(err => {
        console.log(err);
        reject(err);
        setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
      });
    } else {
      callApi('users', 'GET', null, token)
      .then(res => {
        if (res && res.status === 200) {
          dispatch(actFindUsers(res.data.results));
          resolve(res.data);
          setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
        }
      })
      .catch(err => {
        console.log(err);
        reject(err);
        setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
      });
    }
  });
}
}

export const actFindUsers = (users) => {
  return {
    type: Types.FIND_USERS,
    users
  }
}

export const actDeleteUserRequest = (id, token) => {
  return async dispatch => {
    await callApi(`users-reset-job-cancel/${id}`, 'GET', null, token);
    dispatch(actDeleteUser(id));
  }
}
export const actLockUserRequest = (id,deactive, token) => {
  return async dispatch => {
    await callApi(`change-status-lock-user/${id}/${deactive}`, 'GET', null, token);
    dispatch(actLockUser(id));
  }
}

export const actDeleteUser = (id) => {
  return {
    type: Types.REMOVE_USER,
    id
  }
}
export const actLockUser = (id) => {
  return {
    type: Types.LOCK_USER,
    id
  }
}
export const actAddStaffRequest = (token, data) => {
  if (data.email.length === 0){
    return async dispatch => {
    toast.error("Email & Password không được để trống.")
    }
      
  }else{
    return async dispatch => {
      const res = await callApi('staffs', 'POST', data, token);
     
      if (res && res.status === 200 && res.data.id && res.data.id.length>0) {
        toast.success('Tạo Người Dùng Thành Công')
        dispatch(actAddUser(res.data));
      }else{
        toast.error(res.data.mes?res.data.mes:"Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ admin của bạn.")
      }
    }
  }
  
}
export const actAddUserRequest = (token, data) => {
  return async dispatch => {
    const res = await callApi('users', 'POST', data, token);
    if (res && res.status === 200) {
      toast.success('Add new User is success')
      dispatch(actAddUser(res.data));
    }
  }
}

export const actAddUser = (data) => {
  return {
    type: Types.ADD_USER,
    data
  }
}

export const actGetUserRequest = (token, id) => {
  return async dispatch => {
    await callApi(`users/${id}`, 'GET', null, token);
  };
}

export const actEditUserRequest = (token, id, data) => {
  return async dispatch => {
    let endpoint = "users"
    if (data.type !== undefined && data.type === "shop-data"){
        endpoint = "shop-data"
    }
    const res = await callApi(`${endpoint}/${id}`, 'PUT', data, token);
    if (res && res.status === 200) {
      await dispatch(actEditUser(res.data));
      toast.success('Cập nhật thành công!')
    }
  }
}

export const actEditUser = (data) => {
  return {
    type: Types.EDIT_USER,
    data
  }
}
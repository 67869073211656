import React, { Component } from 'react'
import MyFooter from '../../MyFooter/MyFooter'
import { connect } from 'react-redux'
import callApi from '../../../utils/apiCaller';
import { actAddOrderRequest, actGetOrderRequest, actEditOrderRequest } from '../../../redux/actions/order';
import { Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import { currencyFormatVND,currencyVNDToNum } from '../../../utils/util'
let token;
let id;
const ref = React.createRef();
class ActionJob extends Component {
  constructor(props) {
    
    super(props);
    this.state = {
      staff:props.staff,
      dialog: false,
      jobDetail:{}
    };
    id = this.props.id
  }
 
  async componentDidMount() {
    token = localStorage.getItem('_auth');
    
    if (id) {
      const res = await callApi(`jobs/${id}`, 'GET', null, token);
        this.setState({
          jobDetail: res.data.response,
        
      })
    } else {
      
      this.setState({
        provinces: [],
        states: []
      })
    }
  }

  closeDialog = () => {
    this.setState({
      dialog: false
    })
}
parseSkill = (skills) => {
  console.log("skills "+ JSON.stringify(skills))
  let skillStr = ""
  skills.forEach((item,index)=>{
    if (item.parentId&&item.parentId.length>0){
      if (index!=0){
        skillStr = skillStr + ", "+ item.name
      }else{
        skillStr = skillStr + item.name
      }
    }
  });
  return skillStr
 
};
showOrder(status){
  if (status === 'PENDING') { 
    return (<label style={{fontWeight: 'bold', color:"red"}} >Đợi ứng tuyển</label>)
  
  }
  if (status === 'APPLY') {
    return (
      <label  style={{fontWeight: 'bold', color:"red"}}>Đã ứng tuyển</label>
    )
   
  }
  if (status === 'ACCEPT') {
    return (
<label  style={{fontWeight: 'bold', color:"red"}}>Đã nhân việc</label>
    )
    
  }
  if (status === 'DONE') {
    return (
 <label   style={{fontWeight: 'bold', color:"red"}}>Hoàn thành</label>
    )
    
  }
  if (status === 'DELETE') {
    return (
      <label style={{fontWeight: 'bold', color:"red"}} >Đã hủy</label>
    )
   
  }
  
}
 showInMapClicked = (location) => {
console.log(JSON.stringify(location))

 
  const newWindow = window.open("https://maps.google.com?q="+location[0]+","+location[1], '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
 
};
  render() {
    const {  jobDetail, redirectToOrder } = this.state;
   
    if (redirectToOrder) {
      return <Redirect to='/cong-viec'></Redirect>
    }
    if (jobDetail.id===undefined) {
      return null
    }else

    return (
      <div className="content-inner">

        
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">Công việc</h2>
          </div>
        </header>
       
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Trang chủ</a></li>
            <li className="breadcrumb-item active">Công việc</li>
          </ul>
        </div>
        
        {/* Forms Section*/}
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              {/* Form Elements */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h2 className="h4">{jobDetail.infor.title}</h2>
                    
                  </div>
                  <div className="card-body">
              
                    <form className="form-horizontal" onSubmit={(event) => this.handleSubmit(event)} >
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Người tạo</label>
                        <div className="col-sm-3">
                        {jobDetail.owner.name}
                        </div>
                        <label className="col-sm-3 form-control-label">Số điện thoại</label>
                        <div className="col-sm-3">
                        {jobDetail.owner.phone}
                       
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Trạng thái</label>
                        <div className="col-sm-6" bold>
                        {this.showOrder(jobDetail.status)}
                        </div>
                        
                      </div>
                      <div  className="form-group row">
                        <label className="col-sm-3 form-control-label">Nơi làm việc</label>
                        <div  onClick={()=>
                          this.showInMapClicked(jobDetail.infor.geometry.coordinates)
                          } style={{cursor: "pointer",textDecorationLine: "underline" }} className="col-sm-8">
                        {jobDetail.infor.geometry.formatted_address}
                        </div>
                        </div>

                      {/* <div className="line" /> */}
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Ngày giờ làm</label>
                        <div className="col-sm-3">
                        {jobDetail.infor.timeType ===0 ?
                                  "Cần ngay"
                                  :<Moment format="HH:mm DD/MM/YYYY">
                                    {jobDetail.infor.timeStart}
                                  </Moment>
                                  }
                        </div>
                        <label className="col-sm-3 form-control-label">Ngày giờ tạo</label>
                        <div className="col-sm-3">
                        <Moment format="HH:mm DD/MM/YYYY">
                                    {jobDetail.createdat}
                                  </Moment>
                        </div>
                      </div>
                      
                      {/* <div className="line" /> */}
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Yêu cầu kỹ năng</label>
                        <div className="col-sm-8">
                        {this.parseSkill(jobDetail.infor.skills)}
                       
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Giá</label>
                        <div className="col-sm-3">
                        {currencyFormatVND(jobDetail.infor.cost)} VND
                       
                        </div>
                        <label className="col-sm-3 form-control-label">Số giờ</label>
                        <div className="col-sm-3">
                        {jobDetail.infor.jobType===0?"~ "+jobDetail.infor.timeToRent+" giờ":"Khoán việc"}
                        </div>
                      </div>
                    
                      

                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Mô tả</label>
                        <div className="col-sm-8">
                        {jobDetail.infor.description}
                        </div>
                      </div>

                      <div className="line" />
                     {jobDetail.worker.id.length>0?
                      <div>
                     <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Người nhận việc</label>
                        <div className="col-sm-3">
                        {jobDetail.worker.name}
                       
                        </div>
                        <label className="col-sm-3 form-control-label">Rating</label>
                        <div className="col-sm-3">
                       
                        {parseFloat(jobDetail.worker.rating).toFixed(2)}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Số điện thoại</label>
                        <div className="col-sm-3">
                        {jobDetail.worker.phone}
                       
                        </div>
                        <label className="col-sm-3 form-control-label">Giới tính - Ngày sinh</label>
                        <div className="col-sm-3">
                        {jobDetail.worker.sex==="male"?"Nam":"Nữ"} - {jobDetail.worker.year_of_birth}
                        </div>
                      </div>
                      <div className="line" />
                     </div>:null}

                      

                     <div className="line" />

<div className="form-group row">
  <label className="col-sm-3 form-control-label" style={{paddingTop: 50}}>Người nhận</label>
  <div className="col-sm-9">
  <div className="card-body">
<div className="table-responsive">
<table className="table table-hover" ref={ref}>
  <thead>
    <tr>
      <th>No.</th>
      <th>Tên</th>
      <th>Số điện thoại</th>
      <th>Địa chỉ</th>
      <th>Nhận lúc</th>

      
    </tr>
  </thead>
  <tbody>
    {jobDetail.workertrack && jobDetail.workertrack.length ? jobDetail.workertrack.map((item, index) => {
  
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{item.name}</td>
          <td>{item.phone} </td>
          <td>{item.formatted_address}</td>
          
          <td><Moment format="HH:mm DD/MM/YYYY">
                                    {item.createdat}
                                  </Moment> </td>
          
        </tr>
      )
    }) : null}

  </tbody>
</table>
</div>
</div>
  </div>
</div>
<div className="line" />




                      {/* <div className="form-group row">
                        <div className="col-sm-4 offset-sm-3">
                          <button type="reset" className="btn btn-secondary" style={{ marginRight: 2 }}>Cancel</button>
                          <button type="submit" className="btn btn-primary">Lưu thay đổi</button>
                        </div>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/}
        <MyFooter></MyFooter>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add_order: (token, newOrder) => {
      dispatch(actAddOrderRequest(token, newOrder))
    },
    get_order: (token, id) => {
      dispatch(actGetOrderRequest(token, id))
    },
    edit_order: (token, id, data) => {
      dispatch(actEditOrderRequest(token, id, data))
    }
  }
}
const mapStateToProps = (state) => {
   if (state.infoMe?.role && state.infoMe?.role?.description){
    return {
      staff:state.infoMe?.role?.description.toUpperCase() +" "+ state.infoMe?.name.toUpperCase()
   }
  }
  
}
export default connect(mapStateToProps, mapDispatchToProps)(ActionJob)

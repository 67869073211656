import React, { Component } from 'react'
import MyFooter from '../../MyFooter/MyFooter'
import { connect } from 'react-redux'
import callApi from '../../../utils/apiCaller';
import { actAddStaffRequest, actGetUserRequest, actEditUserRequest } from '../../../redux/actions/user';
import { Redirect } from 'react-router-dom';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';


let token;
let id;
const override = css`
    display: block;
    margin: 0 auto;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
`;
class ActionUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData:{},
      redirectToUser: false,
      loading: false
    };
    id = this.props.id
  }
  async componentDidMount() {
    token = localStorage.getItem('_auth');
    if (id) {
      const res = await callApi(`users/${id}`, 'GET', null, token);
      this.setState({
        userData: res.data.results.response
      })
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let editUser ={}
    await this.props.edit_user(token, id, editUser);
      this.setState({
        loading: false,
        redirectToUser: false
      })
  }
   parseSkill = (skills) => {
    console.log("skills "+ JSON.stringify(skills))
    let skillStr = ""
    skills.forEach((item,index)=>{
      if (item.parentId&&item.parentId.length>0){
        if (index!=0){
          skillStr = skillStr + ", "+ item.name
        }else{
          skillStr = skillStr + item.name
        }
      }
    });
    return skillStr
   
  };
  render() {
    const { redirectToUser, loading,userData } = this.state;
    if (redirectToUser) {
      return <Redirect to="/users"></Redirect>
    }else
    if(userData.id!=undefined){
      return (
        <div className="content-inner">
          {/* Page Header*/}
          <div className='sweet-loading'>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={30}
              color={'#796aeebd'}
              loading={loading}
            />
          </div>
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="no-margin-bottom">Người Dùng</h2>
            </div>
          </header>
          {/* Breadcrumb*/}
          <div className="breadcrumb-holder container-fluid">
            <ul className="breadcrumb">
              <li className="breadcrumb-item"><a href="/users">⬅ Quay Lại</a></li>
              
            </ul>
          </div>
          {/* Forms Section*/}
          <section className="forms">
            <div className="container-fluid">
              <div className="row">
                {/* Form Elements */}
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header d-flex align-items-center">
                      <h3 className="h4">Thông Tin Người Dùng</h3>
                    </div>
                    <div className="card-body">
                      <form className="form-horizontal" onSubmit={(event) => this.handleSubmit(event)}>
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">Tên</label>
                          <div className="col-sm-3">
                          {userData.name}
                          </div>
                          <label className="col-sm-3 form-control-label">Số Điện Thoại</label>
                          <div className="col-sm-3">
                          {userData.phone} </div>
                        </div>
     
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">Ngày sinh</label>
                          <div className="col-sm-3">
                          {userData.year_of_birth}
                          </div>
                          <label className="col-sm-3 form-control-label"> Giới Tính</label>
                          <div className="col-sm-3">
                          {userData.sex==="male"?"Nam":"Nữ"} </div>
                          
                        </div>
                        
                        
                       
                        <div className="form-group row">
                          <label htmlFor="fileInput" className="col-sm-3 form-control-label">Hình Đại Diện</label>
                          <div className="col-sm-9">
                             <div className="fix-cart">
                            <img src={userData.avatar || 'http://via.placeholder.com/400x300'} id="output" className="fix-img" alt="avatar" />
                            </div>                     
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">Địa Chỉ</label>
                          <div className="col-sm-8">
                          {userData.geometry.formatted_address}
                          </div>
                           
                        </div>
                        <div className="line" />


                        
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">Đánh giá</label>
                          <div className="col-sm-3">
                          {parseFloat(userData.rating).toFixed(2)}
                          </div>
                          <label className="col-sm-3 form-control-label">Số công việc hoàn thành </label>
                          <div className="col-sm-3">
                          {userData.totaljob} </div>
                          
                        </div>
                        <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tình trạng việc làm</label>
                          <div className="col-sm-3">
                          {userData.job_status.title}
                          </div>
                        <label className="col-sm-3 form-control-label">Số công việc đã hủy</label>
                          <div className="col-sm-3">
                          {userData.nbcancel} </div>
                           
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">Kỹ năng</label>
                          <div className="col-sm-8">
                          {this.parseSkill(userData.skills)}
                          </div>
                         
                        </div>
     
                       
                        <div className="line" />
  
  
                        
                        
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Page Footer*/}
          <MyFooter></MyFooter>
        </div>
      )
    }else{
      return null
    }
   
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add_user: (token, newUser) => {
      dispatch(actAddStaffRequest(token, newUser))
      // dispatch(actAddUserRequest(token, newUser))
    },
    get_user: (token, id) => {
      dispatch(actGetUserRequest(token, id))
    },
    edit_user: (token, id, data) => {
      dispatch(actEditUserRequest(token, id, data))
    }
  }
}
export default connect(null, mapDispatchToProps)(ActionUser)

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import { actFetchRolesRequest, actDeleteRoleRequest, actFindRolesRequest } from '../../../redux/actions/role'
import { connect } from 'react-redux'
import MyFooter from '../../MyFooter/MyFooter'
import {exportExcel} from '../../../utils/exportExcel'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Paginator from 'react-js-paginator';
const MySwal = withReactContent(Swal)

let token;
class Role extends Component {
  render() {
    return (
        <div>
            hello
        </div>
    )
}
}

export default connect()(Role)
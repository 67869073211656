import React, { Component } from 'react'
import  Report  from '../components/Content/Report/Report'

export default class ReportPage extends Component {
  render() {
    return (
        <Report></Report>
    )
  }
}

import React, { Component } from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { actFetchJobsRequest, actDeleteJobRequest, actFindJobsRequest } from '../../../redux/actions/job';
import Swal from 'sweetalert2'
import Moment from 'react-moment';
import withReactContent from 'sweetalert2-react-content'
import MyFooter from '../../MyFooter/MyFooter'
import Paginator from 'react-js-paginator';
import {exportExcel} from '../../../utils/exportExcel'
const MySwal = withReactContent(Swal)

let token;
class Job extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusFilter:"All",
      searchText: '',
      total: 0,
      currentPage: 1
    }
  }


  componentDidMount() {
    this.fetch_reload_data("All"); //recive data from return promise dispatch
  }

  fetch_reload_data(status){
    token = localStorage.getItem('_auth');
    this.props.fetch_jobs(token,null,status).then(res => {
      this.setState({
        total: res.total
      });
    }).catch(err => {
      console.log(err);  
    })
  }

  pageChange(content){
    const limit = 10;
    const offset = limit * (content - 1);
    this.props.fetch_jobs(token, offset,this.state.statusFilter);
    this.setState({
      currentPage: content
    })
    window.scrollTo(0, 0);
  }

  handleRemove = (id) => {
    MySwal.fire({
      title: 'Xác nhận',
      text: "Bạn có muốn xóa đơn hàng này?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Không',

    }).then(async (result) => {
      if (result.value) {
        await this.props.delete_job(id, token);
        Swal.fire(
          'Đã xóa!',
          'Đơn hàng đã được xóa',
          'success'
        )
      }
    })
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  handleFilter = (event) => {
    let value = event.target.value
    this.setState({
      statusFilter:value,
      currentPage: 1
    })
    
    event.preventDefault();
    this.fetch_reload_data(value);
 
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { searchText } = this.state;
    this.props.find_job(token, searchText).then(res => {
      this.setState({
        searchText:"",
        total: res.total
      }) 
    })
  }

  downloadExcel = () => {
    const key = 'jobs'
    exportExcel(key)
  }

  showStatus(status){
    if (status === 'PENDING') { 
      return (<div className="col-md-6"><label  >Đợi ứng tuyển</label></div>)
    
    }
    if (status === 'APPLY') {
      return (
        <div className="col-md-3"><label  >Đã ứng tuyển</label></div>
      )
     
    }
    if (status === 'ACCEPT') {
      return (
   <div className="col-md-3"><label  >Đã nhân việc</label></div>
      )
      
    }
    if (status === 'DONE') {
      return (
   <div className="col-md-3"><label   >Hoàn thành</label></div>
      )
      
    }
    if (status === 'DELETE') {
      return (
        <div className="col-md-3"><label  >Đã hủy</label></div>
      )
     
    }
    
  }

  render() {
    const { jobs } = this.props;
    const { searchText, total } = this.state;
    return (
      <div className="content-inner">
        {/* Page Header*/}
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">Công Việc</h2>
          </div>
        </header>
        {/* Breadcrumb*/}
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Trang chủ</Link></li>
            <li className="breadcrumb-item active">Công việc</li>
          </ul>
        </div>
        <section className="tables pt-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">Danh sách công việc</h3>
                    
                  </div>
                  
                  <form
                    onSubmit={(event) => this.handleSubmit(event)}
                    className="form-inline md-form form-sm mt-0" style={{ justifyContent: 'flex-end', paddingTop: 5, paddingRight: 20 }}>
        
                    <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Lọc</label>
                        <div className="col-sm-9">
                          <select name="filter" className="form-control form-control-sm ml-3 w-75" value={this.state.statusFilter} onChange={this.handleFilter}>
                          <option value='All'>Tất cả</option>
                            <option value='PENDING'>Đợi ứng tuyển</option>
                            <option value='APPLY'>Đã ứng tuyển</option>
                            <option value='ACCEPT' >Đã nhân việc</option>
                            <option value='DONE' >Hoàn thành</option>
                  
                            <option value='DELETE' >Đã hủy</option>
                          </select>
                        </div>
                      </div>
                    {/* <Link to="/cong-viec/add" className="btn btn-primary" > Tạo mới đơn hàng</Link> */}
                  </form>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Tiêu đề</th>
                            <th>Địa chỉ</th>
                            <th>Giờ làm</th>
                            <th>Giờ Tạo</th>
                            <th>Người tạo</th>
                            <th>Trạng thái</th>
                            <th>Hành động</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobs && jobs.length ? jobs.map((item, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{(this.state.currentPage -1)*10 +( index + 1)}</th>
                                <td>{item.infor.title}</td>
                                <td>{item.infor.geometry.formatted_address}</td>                               
                                <td>{item.infor.timeType ===0 ?
                                  "Cần ngay"
                                  :<Moment format="HH:mm DD/MM/YYYY">
                                    {item.infor.timeStart}
                                  </Moment>
                                  }
                                </td>
                                            
                               
                                <td>
                                  <Moment format="HH:mm DD/MM/YYYY">
                                    {item.createdat}
                                  </Moment>
                                </td>
                              

                            
                                <td>{item.owner.name}</td>
                                <td>{this.showStatus(item.status)}</td>
                                <td>
                                  <div>
                                    <span title='Edit' className="fix-action"><Link to={`/cong-viec/edit/${item.id}`}> <i className="fa fa-eye"></i></Link></span>
                                    {/* <span title='Delete' onClick={() => this.handleRemove(item.id)} className="fix-action"><Link to="#"> <i className="fa fa-trash" style={{ color: '#ff00008f' }}></i></Link></span> */}
                                  </div>
                                </td>
                              </tr>
                            )
                          }) : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <nav aria-label="Page navigation example" style={{ float: "right" }}>
                  <ul className="pagination">
                  <Paginator
                        pageSize={10}
                        totalElements={total}
                        onPageChangeCallback={(e) => {this.pageChange(e)}}
                      />
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/}
        <MyFooter></MyFooter>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    jobs: state.jobs
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetch_jobs: (token, offset,statusFilter) => {
      return dispatch(actFetchJobsRequest(token, offset,statusFilter))
    },
    delete_job: (id, token) => {
      dispatch(actDeleteJobRequest(id, token))
    },
    find_job: (token, searchText) => {
      return dispatch(actFindJobsRequest(token, searchText))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Job)

import React, { Component } from 'react'
import ActionJob from '../components/Content/Job/ActionJob'

export default class ActionJobPage extends Component {
  render() {
    const { match } = this.props;
    let id;
    if (match) {
      id = match.params.id;
    }
    return (
      <ActionJob id={id}></ActionJob>
    )
  }
}

import * as Types from "../../constants/ActionType";
import callApi from "../../utils/apiCaller";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { actShowLoading, actHiddenLoading } from './loading'

export const actFetchJobsRequest = (token, offset,statusFilter) => {
  const newOffset = offset === null || offset === undefined ? 0 : offset;
  const newStatusFilter = statusFilter === null || statusFilter === undefined ? "All" : statusFilter;

  const limit = 10;
  return dispatch => {
    dispatch(actShowLoading());
    return new Promise((resolve, reject) => {
      callApi(`jobs?status=${newStatusFilter}&limit=${limit}&offset=${newOffset}&orderBy=-createdAt`, "GET", null, token)
        .then(res => {
          if (res && res.status === 200) {
            dispatch(actFetchJobs(res.data.results));
            resolve(res.data);
            setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
          }
        })
        .catch(err => {
          console.log(err);
          reject(err);
          setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
        });
    });
  };
};

export const actFetchJobs = jobs => {
  return {
    type: Types.FETCH_JOBS,
    jobs
  };
};

export const actFindJobsRequest = (token, searchText) => {
  return dispatch => {
  dispatch(actShowLoading());
  return new Promise((resolve, reject) => {
    if (searchText !== undefined && searchText !== null && searchText !== '') {
      callApi(`jobs?q=${searchText}`, "GET", null, token)
      .then(res => {
        if (res && res.status === 200) {
          dispatch(actFindJobs(res.data.results));
          resolve(res.data);
          setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
        }
      })
      .catch(err => {
        console.log(err);
        reject(err);
        setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
      });
    } else {
      callApi("jobs", "GET", null, token)
      .then(res => {
        if (res && res.status === 200) {
          dispatch(actFindJobs(res.data.results));
          resolve(res.data);
          setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
        }
      })
      .catch(err => {
        console.log(err);
        reject(err);
        setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
      });
    }
  });
}
}

export const actFindJobs = jobs => {
  return {
    type: Types.FIND_JOBS,
    jobs
  };
};

export const actDeleteJobRequest = (id, token) => {
  return async dispatch => {
    await callApi(`jobs/${id}`, "DELETE", null, token);
    dispatch(actDeleteJob(id));
  };
};

export const actDeleteJob = id => {
  return {
    type: Types.REMOVE_JOB,
    id
  };
};

export const actAddJobRequest = (token, data) => {
  return async dispatch => {
    const res = await callApi("job", "POST", data, token);
    if (res && res.status === 200) {
      toast.success("Add new job is success");
      dispatch(actAddJob(res.data));
    }
  };
};

export const actAddJob = data => {
  return {
    type: Types.ADD_JOB,
    data
  };
};

export const actGetJobRequest = (token, id) => {
  return async dispatch => {
    await callApi(`jobs/${id}`, "GET", null, token);
  };
};

export const actEditJobRequest = (token, id, data) => {
  return async dispatch => {
    const res = await callApi(`jobs/${id}`, "PUT", data, token);
    if (res && res.status === 200) {
      toast.success("Cập nhật thành công!");
      dispatch(actEditJob(res.data));
    }
  };
};

export const actEditJob = data => {
  return {
    type: Types.EDIT_JOB,
    data
  };
};

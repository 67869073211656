import React, { Component } from 'react'
import MyFooter from '../../MyFooter/MyFooter'
import { actAddRoleRequest, actGetRoleRequest, actEditRoleRequest } from '../../../redux/actions/role';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import callApi from '../../../utils/apiCaller';
let token;
let id;
class ActionRole extends Component {
  
  render() {
    return (
        <div>
            hello
        </div>
    )
}
}


const mapDispatchToProps = (dispatch) => {
  return {
    add_role: (token, newRole) => {
      dispatch(actAddRoleRequest(token, newRole))
    },
    get_role: (token, id) => {
      dispatch(actGetRoleRequest(token, id))
    },
    edit_role: (token, id, data) => {
      dispatch(actEditRoleRequest(token, id, data))
    }
  }
}
export default connect(null, mapDispatchToProps)(ActionRole)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { startLoading, doneLoading } from '../../utils/loading'
import { actLoginRequest } from '../../redux/actions/auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import callApi from '../../utils/apiCaller';
toast.configure()
class ChangePassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      password: '',
     
    }
  }

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [name]: value
    });
  }


  handleSubmit = async event => {
    event.preventDefault();
    const { password } = this.state;
    startLoading();
    const res = await callApi('change-password-by-token', 'POST',{
      "token":this.props.token,
      "email":this.props.email,
      "password":password
    });
   
    if (res && res.data.status !== 200) {
      toast.error('Có lỗi xảy ra. Vui lòng kiểm tra lại.')
    }
    else{
      toast.success("Bạn đã đổi pasword thành công.");
      setTimeout(function(){ window.location.href = "/login?email="+res.data.email  }, 2000);

      

    }

    doneLoading();
  }
  render() {
    const {  password } = this.state;
    return (
      <div className="page login-page">
        <div className="container d-flex align-items-center">
          <div className="form-holder has-shadow">
            <div className="row">
              {/* Logo & Information Panel*/}
              <div className="col-lg-6">
                <div className="info d-flex align-items-center">
                  <div className="content">
                    <div className="logo">
                      <h1>ĐỔI MẬT KHẤU</h1>
                    </div>
                  </div>
                </div>
              </div>
              {/* Form Panel    */}
              <div className="col-lg-6 bg-white">
                <div className="form d-flex align-items-center">
                  <div className="content">
                    <form onSubmit={(event) => this.handleSubmit(event)}>
                    <label  className="label-material" style={{color:"red",}}>Đổi mật khẩu cho email {this.props.email}</label>
                    
                      <div className="form-group">
                        <input onChange={this.handleChange} type="password" name="password" value={password} className="input-material" placeholder="Nhập Password mới" />
                      </div>
                      <button className="btn btn-primary">Đổi mật khẩu</button>
                    </form>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrights text-center">
          <p>Copyright ©2022 All rights reserved by  <a href="https://fastjob.space" className="external">FAST JOB CO.LTD</a>
          </p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginRequest: (user) => {
      dispatch(actLoginRequest(user))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
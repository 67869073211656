export const config = {
  api: {
    url: 'https://api.fastjob.space/api/v1',
     url_public: 'https://api.fastjob.space/public-api/v1',
    url_cdn: 'https://api.fastjob.space/cdn-api/v1'
    //   url: 'http://localhost:8080/api/v1',
    //   url_public: 'http://localhost:8080/public-api/v1',
    //  url_cdn: 'http://localhost:8080/cdn-api/v1'
  },

}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom';
import { startLoading, doneLoading } from '../../utils/loading'
import { actLoginRequest } from '../../redux/actions/auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import callApi from '../../utils/apiCaller';
toast.configure()
class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    }
  }

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [name]: value
    });
  }


  handleSubmit = async event => {
    event.preventDefault();
    const { email } = this.state;
    startLoading();
    const res = await callApi('forgot-password/'+email, 'GET');
   
    if (res && res.data.status === 200) {
      toast.success('Chúng tôi đã gửi pasword mới đến Email '+email+', vui lòng kiểm tra!')
    }else
    if (res && res.data.status === 403) {
      toast.error('Địa chỉ email '+ email +" chưa đăng ký trên hệ thống. Vui lòng kiểm tra lại")

    }else{
      toast.error("Có lỗi xảy ra. Vui lòng kiểm tra lại")

    }

    doneLoading();
  }
  render() {
    const { email } = this.state;
    const { auth } = this.props;
    if (auth !== null) {
      return <Redirect to="/"></Redirect>
    }
    return (
      <div className="page login-page">
        <div className="container d-flex align-items-center">
          <div className="form-holder has-shadow">
            <div className="row">
              {/* Logo & Information Panel*/}
              <div className="col-lg-6">
                <div className="info d-flex align-items-center">
                  <div className="content">
                    <div className="logo">
                      <h1>QUÊN MẬT KHẨU?</h1>
                    </div>
                  </div>
                </div>
              </div>
              {/* Form Panel    */}
              <div className="col-lg-6 bg-white">
                <div className="form d-flex align-items-center">
                  <div className="content">
                    <form onSubmit={(event) => this.handleSubmit(event)}>
                      <div className="form-group">
                        <input onChange={this.handleChange} type="email" name="email" value={email} className="input-material" placeholder="Nhập Email của bạn để lấy lại mật khẩu" />
                        {/* <label  className="label-material">Email</label> */}
                      </div>
                      
                      <button className="btn btn-primary">Lấy lại mật khẩu</button>
                      
                    </form>
                    <Link to="#" onClick={this.props.forgotClick} className="forgot-pass" style={{marginTop:15}}>Quay lại trang đăng nhập</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrights text-center">
          <p>Copyright ©2022 All rights reserved by  <a href="https://fastjob.space" className="external">FAST JOB CO.LTD</a>
          </p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginRequest: (user) => {
      dispatch(actLoginRequest(user))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
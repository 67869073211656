import React, { Component } from 'react'
import  Job  from '../components/Content/Job/Job'

export default class JobPage extends Component {
  render() {
    return (
        <Job></Job>
    )
  }
}
